
<!--定期更换明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('regular_replacementDetails') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="info"
            label-width="120px"
            label-position="left"
        >
          <div class="title">{{ $t('basic_information') }}</div>
          <el-form-item :label="$t('deviceName')" prop="name">
            <div>{{info.name}}</div>
          </el-form-item>
          <el-form-item :label="$t('submission_sheet_number')" prop="" style="max-width: 1000px">
            <div>{{info.code}}</div>
          </el-form-item>
          <el-form-item :label="$t('submit_time')" prop="code">
            <div>{{info.date}}</div>
          </el-form-item>
          <el-form-item :label="$t('replacement_item')" prop="name">
            <div>{{info.project}}</div>
          </el-form-item>
          <el-form-item :label="$t('device_state')" prop="code">
            <div style="color: #37C239" v-if="info.result === '1'">
              {{ $t('approved') }}
            </div>
            <div style="color: #666666" v-if="info.result === '2'">
              {{ $t('rejected') }}
            </div>
            <div style="color: #666666" v-if="info.result === '3'">
              {{ $t('overtime_cancellation') }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('remark')" prop="" style="max-width: 1000px">
            <div>{{info.remark}}</div>
          </el-form-item>

          <div v-if="info.approveDate !== null">
            <div class="title">{{ $t('approval_information') }}</div>
            <el-form-item :label="$t('approval_result')" prop="code">
              <div style="color: #37C239" v-if="info.result === '1'">
                {{ $t('approved') }}
              </div>
              <div style="color: #666666" v-if="info.result === '2'">
                {{ $t('rejected') }}
              </div>
              <div style="color: #666666" v-if="info.result === '3'">
                {{ $t('overtime_cancellation') }}
              </div>
            </el-form-item>
            <el-form-item :label="$t('approver')" prop="" style="max-width: 1000px">
              <div>{{info.approvePerson}}</div>
            </el-form-item>
            <el-form-item :label="$t('approval_time')" prop="code">
              <div>{{info.approveDate}}</div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'

export default {
  name: "replacementDetail",
  data() {
    return {
      isUpdate: true,
      info: {

      },
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
    }
  },
  created() {
    this.updateDevice()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    updateDevice() {
      API.getExchangeDetail(this.$route.query.id).then(res => {
        console.log(res)
        if (res.message.success) {
          this.info = res.message.data;
        } else {
          message('error',res.message.message)
        }
      })
    },
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
}
.el-table .hidden-row {
  display: none;
}
</style>
